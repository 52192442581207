import { useState } from "react";
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Logout, Settings } from "@mui/icons-material";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import classes from "./ProfileMenu.module.scss";
import { images } from "../../../../../constants";
import { useModal } from "../../../../../store/modalContext";
import ChangePasswordBox from "../../../../whitelabel/changePassword/ChangePasswordBox";
import config from "../../../../../constants/config";
import useLogout from "../../../../../services/logoutServiceCall";
import { useDevice } from "../../../../../store/deviceContext";
import Timezone from "../../../timezone/Timezone";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { toggleFullScreen } from "../../../../../utils/utils";
// import ProfilePage from "../../../profilePage/ProfilePage";

function ProfileMenu() {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { isDesktop, isMobile, isPortrait, isIPad} = useDevice();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logOutServiceCall } = useLogout();
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event:any, tab:any) => {
    handleClose();
    switch(tab) {
      case 'ChangePassword':
        const modalContent = <ChangePasswordBox/>
        openModal(modalContent, 'Change Password');
        break;
      case 'Profile': navigate(config.roleBasedConfig['whitelabel'].routesConstatnts.routeUserProfile); 
        // const modalContent1 = <ProfilePage/>
        // openModal(modalContent1, 'Profile');
        break;
      case 'FullScreen' : toggleFullScreen();
        break;
      case 'Logout': 
        logOutServiceCall();
        break;
    }
  };

  return (
    <>
      { (isMobile && isPortrait && !isIPad) ?
        <IconButton onClick={handleMenu} color="inherit">
          <AccountCircleIcon />
        </IconButton> :
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title="Settings">
            <>
            <IconButton
              onClick={handleMenu}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
            >
              <img src={process.env.REACT_APP_ADMIN_APP_NAME === 'whitelabel' ? images.avt : images.owner_avt} alt="avatar" style={{ width: 35, height: 35, borderRadius: '50%' }}/>
            </IconButton>
            {isDesktop && <p onClick={handleMenu} style={{marginLeft: '5px', cursor: "pointer"}} className={classes.profile__userName}>{localStorage.getItem('user')}</p>} 
            </>
          </Tooltip>
        </Box>
      }
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            scale: '0.8',
            overflowY: 'auto',
            minWidth: '270px !important',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'Profile')}>
        <h3>{'Hello! ' + localStorage.getItem('user')?.toUpperCase()}</h3> 
        </MenuItem>
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'Profile')}>
          <Avatar /> Profile
        </MenuItem>

        <Divider />
        <MenuItem>
          <ListItemIcon>
            <MoreTimeIcon fontSize="small" />
          </ListItemIcon>
          <Timezone />
        </MenuItem>

        {/* {
          process.env.REACT_APP_ADMIN_APP_NAME !== 'whitelabel' && 
          <MenuItem onClick={(event) => handleMenuItemClick(event, 'Myaccount')}>
            <Avatar /> My account
          </MenuItem>
        } */}

        <Divider />
        {
          process.env.REACT_APP_ADMIN_APP_NAME === 'whitelabel' && 
          <MenuItem onClick={(event) => handleMenuItemClick(event, 'ChangePassword')}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>
        }

        <MenuItem onClick={(event) => handleMenuItemClick(event, 'FullScreen')}>
          <ListItemIcon>
            <FullscreenIcon fontSize="small" />
          </ListItemIcon>
          Fullscreen
        </MenuItem>
        
        <MenuItem onClick={(event) => handleMenuItemClick(event, 'Logout')}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
export default ProfileMenu;