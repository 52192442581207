import ThemeBox from "./themeBox/ThemeBox";
import ProfileMenu from "./profile/ProfileMenu";
import WalletMenu from "./wallet/WalletMenu";
import classes from "./TopNavRightBox.module.scss";
import GlobalSearchFeature from "./globalSearch/GlobalSearchFeature";
import { useModulePrivilage } from "../../../../store/modulePrivilageContext";
import { useEffect, useState } from "react";
import { UserRole, WhitelabelModuleNames } from "../../../../utils/enums";
// import FullScreen from "./fullScreen/FullScreen";
// import { useDevice } from "../../../../store/deviceContext";

function TopNavRightBox() {
  // const { isDesktop } = useDevice();
  let { moduleListWL } = useModulePrivilage();
  const [showGlobalSearch, setShowGlobalSearch] = useState(false);
  useEffect(() => {
    if(process.env.REACT_APP_ADMIN_APP_NAME === 'whitelabel') {
      if(localStorage.getItem('role') === UserRole.SUBUSER) {
        if(moduleListWL?.length > 0 && moduleListWL.includes(WhitelabelModuleNames.SUBLEVEL_CREATION)) {
          setShowGlobalSearch(true);
        }
      }else {
        setShowGlobalSearch(true);
      }
    }
  }, []);

  return (
    <div className={classes.topNavBox_right}>
      {/* For mobile responsive code    style={(isMobile && isPortrait) ? {justifyContent: 'start'} : {justifyContent: 'flex-end'}} */}
      <div className={classes.wrapper} style={{justifyContent: 'flex-end'}}>
        { showGlobalSearch && <GlobalSearchFeature /> }
        <ThemeBox />
        <WalletMenu />
        {/* {isDesktop && <FullScreen />} */}
      </div>
      {/* {(isDesktop || isIPad || (isMobile && isLandscape))&&
        <>
          <ThemeBox />
          <FullScreen />
        </>
      } */}
      <ProfileMenu />
    </div>
  );
}
export default TopNavRightBox;