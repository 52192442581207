import { useContext, useEffect, useState } from "react";
import { useWindowSize } from "usehooks-ts";
import SidebarContext from "../../../store/sidebarContext";
import { WhitelabelModuleNames } from "../../../utils/enums";
import GlobalSearchFeature from "./rightBox/globalSearch/GlobalSearchFeature";
import ThemeBox from "./rightBox/themeBox/ThemeBox";
import WalletMenu from "./rightBox/wallet/WalletMenu";
import ProfileMenu from "./rightBox/profile/ProfileMenu";
import { AppBar, Toolbar, IconButton, Typography, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useModulePrivilage } from "../../../store/modulePrivilageContext";

const TopNavMobile = () => {
  const sideOpenCtx = useContext(SidebarContext);
  const { width } = useWindowSize();
  let { moduleListWL } = useModulePrivilage();
  const [showGlobalSearch, setShowGlobalSearch] = useState(false);
  function openSidebarHandler() {
    sideOpenCtx.toggleSidebar();
    if (width <= 768) document.body.classList.toggle("sidebar__open");
  }

  useEffect(() => {
    if(process.env.REACT_APP_ADMIN_APP_NAME === 'whitelabel') {
      if(moduleListWL?.length > 0) {
        if(moduleListWL.includes(WhitelabelModuleNames.SUBLEVEL_CREATION)) {
          setShowGlobalSearch(true);
        }
      }
    }
  }, []);

  return (
    <AppBar position="static" sx={{ backgroundColor: 'var(--primaryColor)' }}>
      <Toolbar sx={{pr: 0, minHeight: '65px'}}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={openSidebarHandler}
          sx={{ mr: 0.5 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="subtitle2" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
          <span>{(process.env.REACT_APP_ADMIN_APP_NAME === 'whitelabel') ? 'ADMIN' : 'OWNER'}</span>
          <br></br>
          <span>PANEL</span>
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
          { showGlobalSearch && <GlobalSearchFeature /> }
          <ThemeBox />
          <WalletMenu />
          <ProfileMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavMobile;