import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, RadioGroup, FormControlLabel, Radio, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTimezone } from "../../../store/timezoneContext";
import moment from 'moment-timezone';

type TimeMap = {
  [key: string]: string;
};

function Timezone() {
  const { timezone, setTimezone } = useTimezone();
  const [times, setTimes] = useState<TimeMap>({});
  const timezones = [
    { label: 'UTC', value: 'UTC' }, // Coordinated Universal Time
    { label: 'GST', value: 'Asia/Dubai' }, // Gulf Standard Time (UTC+4)
    { label: 'IST', value: 'Asia/Kolkata' }, // India Standard Time (UTC+5:30)
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      const newTimes: TimeMap = {};
      timezones.forEach(tz => {
        newTimes[tz.value] = moment().tz(tz.value).format('HH:mm:ss');
      });
      setTimes(newTimes);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleTimezoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimezone(event.target.value);
  };

  return (
    <Accordion onClick={(event) => event.stopPropagation()}
      sx={{
        width: '100%',
        backgroundColor: 'transparent', // Example of overriding background color
        boxShadow: 'none', // Remove default box shadow
        border: 'none', // Custom border
        backgroundImage: 'none',
        margin: '0 !important',
        '&:before': {
          display: 'none',
        },
        '& .MuiAccordionSummary-root': {
          minHeight: '30px',
        }
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          padding: 0,
          '& .MuiAccordionSummary-content': {
            margin: '0', 
          },
        }}
      >
        <Typography>Timezones</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RadioGroup
          aria-label="timezones"
          name="timezones"
          value={timezone}
          onChange={handleTimezoneChange}
        >
          {timezones.map((tz) => (
            <FormControlLabel
              key={tz.value}
              value={tz.value}
              control={<Radio />}
              label={
                <Box display="flex" justifyContent="space-between" width="100%">
                  <span>{tz.label + ":"}</span>
                  <span>&nbsp;{`${times[tz.value] || 'Loading...'}`}</span>
                </Box>
              }
            />
          ))}
        </RadioGroup>
      </AccordionDetails>
    </Accordion>
  );
}

export default Timezone;