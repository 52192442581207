import { IconButton, Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
// import { useModal } from "../../../../../store/modalContext";
// import { GlobalSearch } from "../../../../whitelabel/globalSearch/GlobalSearch";
import { useNavigate } from "react-router-dom";
import { WhitelabelRoutes } from "../../../../../utils/enums";
import { useDevice } from "../../../../../store/deviceContext";
function GlobalSearchFeature() {
  // const { openModal } = useModal();
  const navigate = useNavigate();
  const { isPortrait, isMobile, isIPad} = useDevice();

  const openGlobalSearch = () =>{
    navigate(WhitelabelRoutes.routeGlobalSearch);
    // const modalContent = <GlobalSearch  />
    // openModal(modalContent, 'Global Search', '80%');
  }

  return (
    <Tooltip title="Global Search">
      <div onClick={openGlobalSearch}>
        <div>
          {(isMobile && isPortrait && !isIPad) ?
            <IconButton color="inherit">
              <SearchIcon />
            </IconButton> : 
            <SearchIcon fontSize="large" 
              style={{
                position: "relative",
                top: "1.5px", 
                color: "var(--primaryColor)"
              }}
            />
          }
        </div>
      </div>
    </Tooltip>
  );
}

export default GlobalSearchFeature;