import React, { ReactNode, useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Define the font
const fontFamily = '"Poppins", sans-serif';

type themeType = {
  theme: string;
  toggleTheme: () => void;
  muiTheme?: any;
};

const lightMuiTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0054B5", //Set primaryColor manually for light mode
    },
    text: {
      primary: "#424750",
      secondary: "#424750",
    },
  },
  typography: {
    fontFamily,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "var(--primaryColor)", //"#FF4A00",// Text color
          // backgroundColor: '#ff0000', // Red background color
          "&:hover": {
            backgroundColor: "var(--primaryColor)", //'#FF4A00',
            color: "#fff",
          },
        },
      },
    },
  },
});

const darkMuiTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#0054B5", //Set primaryColor manually for dark mode
    },
    text: {
      primary: "#ebebeb",
      secondary: "#f2f2f2",
    },
  },
  typography: {
    fontFamily,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "var(--primaryColor)", //"#FF4A00",//'#ee384e', // Text color
          // backgroundColor: '#ff0000', // Red background color
          "&:hover": {
            backgroundColor: "var(--primaryColor)", //'#FF4A00',
            color: "#fff", // Darker red on hover
          },
        },
      },
    },
  },
});

const ThemeContext = React.createContext<themeType>({
  theme: "",
  toggleTheme: () => {},
  muiTheme: lightMuiTheme,
});

export const ThemeContextProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  const [theme, setTheme] = useLocalStorage("theme", "light");
  const muiTheme = theme === "dark" ? darkMuiTheme : lightMuiTheme;

  useEffect(() => {
    document.documentElement.setAttribute("theme", theme);
  }, [theme]);

  function toggleTheme() {
    setTheme((prev) => {
      return prev === "light" ? "dark" : "light";
    });
  }

  const themeValue: themeType = {
    theme,
    toggleTheme,
  };
  return (
    <ThemeContext.Provider value={{ ...themeValue, muiTheme }}>
      <ThemeProvider theme={muiTheme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
