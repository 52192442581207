import { createContext, useState, useContext, ReactNode, useEffect, useRef } from 'react';
import { getApiUrlEndpointAsPerRole, removeLocalStorageData } from '../utils/utils';
import getService from '../services/getService';
import { ICommonReturnType } from '../interfaces/interfaceCommon';
import LoginContext from "../store/loginContext";

interface BalanceContextProps {
  balance: number;
  exposure: number;
  creditBalance: number;
  walletType: number;
  myShare: number;
  updateBalance: (amount: number) => void;
  fetchBalance: () => void;
  addBalanceInterval: () => void;
  clearBalanceInterval: () => void;
}

const BalanceContext = createContext<BalanceContextProps | undefined>(undefined);

export const BalanceProvider = ({ children }: { children: ReactNode }) => {
  const [balance, setBalance] = useState<number>(0);
  const [exposure, setExposure] = useState<number>(0);
  const [creditBalance, setCreditBalance] = useState<number>(0);
  const [walletType, setWalletType] = useState<number>(0);
  const [myShare, setMyShare] = useState<number>(0);
  const loginCtx = useContext(LoginContext);
  const balanceCtx = useContext(BalanceContext);

  
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const updateBalance = (amount: number) => {
    setBalance(prevBalance => prevBalance + amount);
  };

  const fetchBalance = async () => {
    try {
      const url = getApiUrlEndpointAsPerRole().getMyWalletApiURL;
      const result: ICommonReturnType = await getService(url);
      if (result && result.data) {
        // if(result.data.totalBalance) {
        //   setBalance(result.data.totalBalance);
        // }
        if (result.data.balance !== null && result.data.balance !== undefined) {
          setBalance(result.data.balance);
        }
        if (result.data.creditBalance !== null && result.data.creditBalance !== undefined) {
          setCreditBalance(result.data.creditBalance);
        }
        if (result.data.exposure !== null && result.data.exposure !== undefined) {
          setExposure(result.data.exposure);
        }
        if (result.data.walletType !== null && result.data.walletType !== undefined) {
          setWalletType(result.data.walletType);
        }
        if (result.data.myShare !== null && result.data.myShare !== undefined) {
          setMyShare(result.data.myShare);
        }
      } else {
        console.error('API response data structure is unexpected:', result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);      
      setBalance(0); // Fallback balance
      // navigate("/login");
      loginCtx.toggleLogin();
      removeLocalStorageData();
      balanceCtx?.clearBalanceInterval();
    }
  }

  function loggedInFetchBalance() {
    fetchBalance();
  }

  useEffect(() => {
    if (loginCtx.isLogin) {
      loggedInFetchBalance();
      addBalanceInterval();
    }
  }, []);

  // Set up an interval to fetch the balance every 15 minutes (900000 ms)
  const addBalanceInterval = () => {
    setBalance(0);
    setCreditBalance(0);
    setExposure(0);
    intervalRef.current = setInterval(() => {
      fetchBalance();
    }, 900000);
    return () => {
      clearBalanceInterval();
    };
  }

  // Cleanup function to clear the interval
  const clearBalanceInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  return (
    <BalanceContext.Provider value={{ balance, exposure, creditBalance, walletType, myShare, updateBalance, fetchBalance, addBalanceInterval, clearBalanceInterval }}>
      {children}
    </BalanceContext.Provider>
  );
};

export const useBalance = () => {
  const context = useContext(BalanceContext);
  if (!context) {
    throw new Error('useBalance must be used within a BalanceProvider');
  }
  return context;
};

export default BalanceContext;